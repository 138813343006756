@media print {
[dir] body {
    background-color: transparent !important;
}
nav.header-navbar,
  .main-menu,
  .content-header-breadcrumb,
  .header-navbar-shadow,
  .customizer-toggle,
  footer.footer {
    display: none !important;
}
[dir] .content.app-content {
    padding-top: 2rem !important;
}
[dir=ltr] .content.app-content {
    margin-left: 0;
}
[dir=rtl] .content.app-content {
    margin-right: 0;
}
[dir] .card {
    background-color: transparent;
    box-shadow: none;
}
.invoice-preview-wrapper .row.invoice-preview .col-md-8 {
    max-width: 100%;
    flex-grow: 1;
}
.invoice-preview-wrapper .row.invoice-preview .invoice-preview-card .card-body:nth-of-type(2) .row > .col-12 {
    max-width: 50% !important;
}
.invoice-preview-wrapper .row.invoice-preview .invoice-preview-card .card-body:nth-of-type(2) .row .col-12:nth-child(2) {
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
}
[dir] .invoice-preview-wrapper .row.invoice-preview .invoice-preview-card .card-body:nth-of-type(2) .row .col-12:nth-child(2) {
    margin-top: 0 !important;
}
.invoice-preview-wrapper .invoice-actions {
    display: none;
}
}