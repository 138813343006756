[dir] .btn-info[data-v-42c24926] {
  border-color: #9c27b0 !important;
  background-color: #9c27b0 !important;
}
[dir=ltr] .invoice-preview .invoice-padding[data-v-42c24926], [dir=ltr] .invoice-edit .invoice-padding[data-v-42c24926], [dir=ltr] .invoice-add .invoice-padding[data-v-42c24926] {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}
[dir=rtl] .invoice-preview .invoice-padding[data-v-42c24926], [dir=rtl] .invoice-edit .invoice-padding[data-v-42c24926], [dir=rtl] .invoice-add .invoice-padding[data-v-42c24926] {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}
[dir=ltr] .invoice-preview .table th[data-v-42c24926]:first-child, [dir=ltr] .invoice-preview .table td[data-v-42c24926]:first-child, [dir=ltr] .invoice-edit .table th[data-v-42c24926]:first-child, [dir=ltr] .invoice-edit .table td[data-v-42c24926]:first-child, [dir=ltr] .invoice-add .table th[data-v-42c24926]:first-child, [dir=ltr] .invoice-add .table td[data-v-42c24926]:first-child {
  padding-left: 2.5rem;
}
[dir=rtl] .invoice-preview .table th[data-v-42c24926]:first-child, [dir=rtl] .invoice-preview .table td[data-v-42c24926]:first-child, [dir=rtl] .invoice-edit .table th[data-v-42c24926]:first-child, [dir=rtl] .invoice-edit .table td[data-v-42c24926]:first-child, [dir=rtl] .invoice-add .table th[data-v-42c24926]:first-child, [dir=rtl] .invoice-add .table td[data-v-42c24926]:first-child {
  padding-right: 2.5rem;
}
.invoice-preview .logo-wrapper[data-v-42c24926],
.invoice-edit .logo-wrapper[data-v-42c24926],
.invoice-add .logo-wrapper[data-v-42c24926] {
  display: flex;
  align-items: center;
}
[dir] .invoice-preview .logo-wrapper[data-v-42c24926], [dir] .invoice-edit .logo-wrapper[data-v-42c24926], [dir] .invoice-add .logo-wrapper[data-v-42c24926] {
  margin-bottom: 1.9rem;
}
.invoice-preview .logo-wrapper .invoice-logo[data-v-42c24926],
.invoice-edit .logo-wrapper .invoice-logo[data-v-42c24926],
.invoice-add .logo-wrapper .invoice-logo[data-v-42c24926] {
  font-size: 2.142rem;
  font-weight: bold;
  letter-spacing: -0.54px;
}
[dir] .invoice-preview .logo-wrapper .invoice-logo[data-v-42c24926], [dir] .invoice-edit .logo-wrapper .invoice-logo[data-v-42c24926], [dir] .invoice-add .logo-wrapper .invoice-logo[data-v-42c24926] {
  margin-bottom: 0;
}
[dir=ltr] .invoice-preview .logo-wrapper .invoice-logo[data-v-42c24926], [dir=ltr] .invoice-edit .logo-wrapper .invoice-logo[data-v-42c24926], [dir=ltr] .invoice-add .logo-wrapper .invoice-logo[data-v-42c24926] {
  margin-left: 1rem;
}
[dir=rtl] .invoice-preview .logo-wrapper .invoice-logo[data-v-42c24926], [dir=rtl] .invoice-edit .logo-wrapper .invoice-logo[data-v-42c24926], [dir=rtl] .invoice-add .logo-wrapper .invoice-logo[data-v-42c24926] {
  margin-right: 1rem;
}
.invoice-preview .invoice-title[data-v-42c24926],
.invoice-edit .invoice-title[data-v-42c24926],
.invoice-add .invoice-title[data-v-42c24926] {
  font-size: 1.285rem;
}
[dir] .invoice-preview .invoice-title[data-v-42c24926], [dir] .invoice-edit .invoice-title[data-v-42c24926], [dir] .invoice-add .invoice-title[data-v-42c24926] {
  margin-bottom: 1rem;
}
.invoice-preview .invoice-title .invoice-number[data-v-42c24926],
.invoice-edit .invoice-title .invoice-number[data-v-42c24926],
.invoice-add .invoice-title .invoice-number[data-v-42c24926] {
  font-weight: 600;
}
.invoice-preview .invoice-date-wrapper[data-v-42c24926],
.invoice-edit .invoice-date-wrapper[data-v-42c24926],
.invoice-add .invoice-date-wrapper[data-v-42c24926] {
  display: flex;
  align-items: center;
}
[dir] .invoice-preview .invoice-date-wrapper[data-v-42c24926]:not(:last-of-type), [dir] .invoice-edit .invoice-date-wrapper[data-v-42c24926]:not(:last-of-type), [dir] .invoice-add .invoice-date-wrapper[data-v-42c24926]:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.invoice-preview .invoice-date-wrapper .invoice-date-title[data-v-42c24926],
.invoice-edit .invoice-date-wrapper .invoice-date-title[data-v-42c24926],
.invoice-add .invoice-date-wrapper .invoice-date-title[data-v-42c24926] {
  width: 7rem;
}
[dir] .invoice-preview .invoice-date-wrapper .invoice-date-title[data-v-42c24926], [dir] .invoice-edit .invoice-date-wrapper .invoice-date-title[data-v-42c24926], [dir] .invoice-add .invoice-date-wrapper .invoice-date-title[data-v-42c24926] {
  margin-bottom: 0;
}
.invoice-preview .invoice-date-wrapper .invoice-date[data-v-42c24926],
.invoice-edit .invoice-date-wrapper .invoice-date[data-v-42c24926],
.invoice-add .invoice-date-wrapper .invoice-date[data-v-42c24926] {
  font-weight: 600;
}
[dir] .invoice-preview .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir] .invoice-edit .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir] .invoice-add .invoice-date-wrapper .invoice-date[data-v-42c24926] {
  margin-bottom: 0;
}
[dir=ltr] .invoice-preview .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir=ltr] .invoice-edit .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir=ltr] .invoice-add .invoice-date-wrapper .invoice-date[data-v-42c24926] {
  margin-left: 0.5rem;
}
[dir=rtl] .invoice-preview .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir=rtl] .invoice-edit .invoice-date-wrapper .invoice-date[data-v-42c24926], [dir=rtl] .invoice-add .invoice-date-wrapper .invoice-date[data-v-42c24926] {
  margin-right: 0.5rem;
}
[dir] .invoice-preview .invoice-spacing[data-v-42c24926], [dir] .invoice-edit .invoice-spacing[data-v-42c24926], [dir] .invoice-add .invoice-spacing[data-v-42c24926] {
  margin: 1.45rem 0;
}
.invoice-preview .invoice-number-date .title[data-v-42c24926],
.invoice-edit .invoice-number-date .title[data-v-42c24926],
.invoice-add .invoice-number-date .title[data-v-42c24926] {
  width: 115px;
}
.invoice-preview .invoice-total-wrapper[data-v-42c24926],
.invoice-edit .invoice-total-wrapper[data-v-42c24926],
.invoice-add .invoice-total-wrapper[data-v-42c24926] {
  width: 100%;
  max-width: 12rem;
}
.invoice-preview .invoice-total-wrapper .invoice-total-item[data-v-42c24926],
.invoice-edit .invoice-total-wrapper .invoice-total-item[data-v-42c24926],
.invoice-add .invoice-total-wrapper .invoice-total-item[data-v-42c24926] {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
[dir] .invoice-preview .invoice-total-wrapper .invoice-total-item .invoice-total-title[data-v-42c24926], [dir] .invoice-edit .invoice-total-wrapper .invoice-total-item .invoice-total-title[data-v-42c24926], [dir] .invoice-add .invoice-total-wrapper .invoice-total-item .invoice-total-title[data-v-42c24926] {
  margin-bottom: 0.35rem;
}
.invoice-preview .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926],
.invoice-edit .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926],
.invoice-add .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926] {
  font-weight: 600;
}
[dir] .invoice-preview .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926], [dir] .invoice-edit .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926], [dir] .invoice-add .invoice-total-wrapper .invoice-total-item .invoice-total-amount[data-v-42c24926] {
  margin-bottom: 0.35rem;
}
@media (min-width: 768px) {
[dir] .invoice-preview .invoice-title[data-v-42c24926], [dir] .invoice-edit .invoice-title[data-v-42c24926], [dir] .invoice-add .invoice-title[data-v-42c24926] {
    margin-bottom: 3rem;
}
[dir=ltr] .invoice-preview .invoice-title[data-v-42c24926], [dir=ltr] .invoice-edit .invoice-title[data-v-42c24926], [dir=ltr] .invoice-add .invoice-title[data-v-42c24926] {
    text-align: right;
}
[dir=rtl] .invoice-preview .invoice-title[data-v-42c24926], [dir=rtl] .invoice-edit .invoice-title[data-v-42c24926], [dir=rtl] .invoice-add .invoice-title[data-v-42c24926] {
    text-align: left;
}
}
[dir] .invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926], [dir] .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
  margin-bottom: 0;
}
[dir=ltr] .invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926], [dir=ltr] .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
  text-align: left;
  margin-right: 3.5rem;
}
[dir=rtl] .invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926], [dir=rtl] .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
  text-align: right;
  margin-left: 3.5rem;
}
.invoice-edit .invoice-preview-card .invoice-edit-input[data-v-42c24926],
.invoice-edit .invoice-preview-card .invoice-edit-input-group[data-v-42c24926],
.invoice-add .invoice-preview-card .invoice-edit-input[data-v-42c24926],
.invoice-add .invoice-preview-card .invoice-edit-input-group[data-v-42c24926] {
  max-width: 11.21rem;
}
[dir] .invoice-edit .invoice-preview-card .invoice-product-details[data-v-42c24926], [dir] .invoice-add .invoice-preview-card .invoice-product-details[data-v-42c24926] {
  background-color: #fcfcfc;
  padding: 3.75rem 3.45rem 2.3rem 3.45rem;
}
[dir] .invoice-edit .invoice-preview-card .invoice-product-details .product-details-border[data-v-42c24926], [dir] .invoice-add .invoice-preview-card .invoice-product-details .product-details-border[data-v-42c24926] {
  border: 1px solid #ebe9f1;
  border-radius: 0.3rem;
}
[dir] .invoice-edit .invoice-preview-card .invoice-to-title[data-v-42c24926], [dir] .invoice-add .invoice-preview-card .invoice-to-title[data-v-42c24926] {
  margin-bottom: 1.9rem;
}
.invoice-edit .invoice-preview-card .col-title[data-v-42c24926],
.invoice-add .invoice-preview-card .col-title[data-v-42c24926] {
  position: absolute;
  top: -3.2rem;
}
.invoice-edit .invoice-preview-card .item-options-menu[data-v-42c24926],
.invoice-add .invoice-preview-card .item-options-menu[data-v-42c24926] {
  min-width: 20rem;
}
[dir] .invoice-edit .invoice-preview-card .repeater-wrapper[data-v-42c24926]:not(:last-child), [dir] .invoice-add .invoice-preview-card .repeater-wrapper[data-v-42c24926]:not(:last-child) {
  margin-bottom: 3rem;
}
.invoice-edit .invoice-preview-card .invoice-calculations .total-amt-title[data-v-42c24926],
.invoice-add .invoice-preview-card .invoice-calculations .total-amt-title[data-v-42c24926] {
  width: 100px;
}
@media (max-width: 769px) {
.invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926],
  .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
    width: 115px;
}
[dir=ltr] .invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926], [dir=ltr] .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
    margin-right: 0;
}
[dir=rtl] .invoice-edit .invoice-preview-card .invoice-title[data-v-42c24926], [dir=rtl] .invoice-add .invoice-preview-card .invoice-title[data-v-42c24926] {
    margin-left: 0;
}
.invoice-edit .invoice-preview-card .invoice-edit-input[data-v-42c24926],
  .invoice-add .invoice-preview-card .invoice-edit-input[data-v-42c24926] {
    max-width: 100%;
}
}
@media (max-width: 992px) {
.invoice-edit .col-title[data-v-42c24926],
  .invoice-add .col-title[data-v-42c24926] {
    top: -1.5rem !important;
}
}
@media print {
[dir] .invoice-edit hr[data-v-42c24926], [dir] .invoice-add hr[data-v-42c24926] {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
}
}
.card-text-details-order li[data-v-42c24926] {
  display: flex;
  align-content: center;
  justify-content: space-between;
  align-items: center;
}
[dir] .card-text-details-order li[data-v-42c24926] {
  padding: 8px 0px;
  border-top: 1px solid #f8f8f8;
}
.card-text-details-order li span[data-v-42c24926]:first-child {
  color: #000;
}
[dir] .card-text-details-order li[data-v-42c24926]:last-child {
  padding-bottom: 0px !important;
}
[dir] .card-text-details-order li[data-v-42c24926]:hover {
  border-top: 1px solid rgba(221, 221, 221, 0.39);
}