.app-timeline[data-v-b179f770] {
  list-style: none;
  position: relative;
}[dir] .app-timeline[data-v-b179f770] {
  padding: 0;
  margin: 0;
}[dir=ltr] .app-timeline[data-v-b179f770] {
  margin-left: 1rem;
}[dir=rtl] .app-timeline[data-v-b179f770] {
  margin-right: 1rem;
}